import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {doAuthenticatedRequest} from '../../../api/requests';
import {handleError} from '../../../api/errorHandling';
import FormInput from '../../../components/forminput/FormInput';
import './TeacherCreate.css';

const TeacherCreateComponent = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        monetizationModel: 'COMMISSION', // Default value
        commissionPercentage: 30,
        promoCode: '',
        trialDays: 7,
        priceId: ''
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState(null);
    const [prices, setPrices] = useState([]); // State for available prices
    const [loadingPrices, setLoadingPrices] = useState(true);


    useEffect(() => {
        const fetchPrices = async () => {
            setLoadingPrices(true);
            try {
                const response = await doAuthenticatedRequest('GET', '/admin/prices');
                setPrices(response.data || []);
            } catch (error) {
                console.error('Error fetching prices:', error);
                setSubmitError('Failed to load price options');
            } finally {
                setLoadingPrices(false);
            }
        };

        fetchPrices();
    }, []);


    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }

        if (formData.monetizationModel === 'COMMISSION') {
            if (formData.commissionPercentage < 1 || formData.commissionPercentage > 100) {
                newErrors.commissionPercentage = 'Commission must be between 1% and 100%';
            }

            if (formData.promoCode !== "" && !formData.promoCode.startsWith("promo_")) {
                newErrors.promoCode = "Promo code must start with 'promo_";
            }

            if (formData.trialDays < 0) {
                newErrors.trialDays = 'Trial days cannot be negative';
            }

            if ((formData.trialDays === 0 || formData.trialDays === "") && formData.promoCode === "") {
                newErrors.trialDays = 'Offer trial days, a promo code, or both';
            }

            if (!formData.priceId || formData.priceId.trim() === '') {
                newErrors.priceId = 'Price selection is required';
            }

        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // This function will be passed to the FormInput component
    const handleFormChange = (target) => {
        const {name, value} = target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        // Clear the error for this field
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: null
            }));
        }
    };

    const handleModelChange = (e) => {
        const monetizationModel = e.target.value;
        setFormData(prev => ({
            ...prev,
            monetizationModel,
            // Reset commission fields if not COMMISSION model
            ...(monetizationModel !== 'COMMISSION' && {
                commissionPercentage: 0,
                promoCode: '',
                trialDays: 0
            })
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitError(null);

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        try {
            // Create the request payload
            const payload = {
                name: formData.name,
                email: formData.email,
                monetizationModel: formData.monetizationModel
            };

            // Add monetization model specific fields
            if (formData.monetizationModel === 'COMMISSION') {
                payload.commissionPercentage = parseFloat(formData.commissionPercentage) / 100;
                payload.promoCode = formData.promoCode;
                payload.trialDays = parseInt(formData.trialDays, 10);
                payload.priceId = formData.priceId;
            }

            const response = await doAuthenticatedRequest('POST', '/teachers', payload);

            // Now the response.data should contain the TeacherAdminDetailDTO
            // with the id field, so this navigation should work
            navigate(`/account/teachers/${response.data.id}`);
        } catch (error) {
            const errorMessage = handleError(error);
            setSubmitError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/account/teachers');
    };

    return (
        <div className="teacher-create-container">
            <div className="form-header">
                <h2>Create New Teacher</h2>
                <p>Add a new teacher to the system</p>
            </div>

            {submitError && (
                <div className="error-message">{submitError}</div>
            )}

            <form onSubmit={handleSubmit} className="teacher-form">
                <div className="form-group">
                    <FormInput
                        type="text"
                        title="Teacher Name"
                        name="name"
                        value={formData.name}
                        handleFormChange={handleFormChange}
                        placeholder="Enter teacher name"
                        valid={!errors.name}
                    />
                    {errors.name && <div className="error-text">{errors.name}</div>}
                </div>

                <div className="form-group">
                    <FormInput
                        type="email"
                        title="Email"
                        name="email"
                        value={formData.email}
                        handleFormChange={handleFormChange}
                        placeholder="Enter email address"
                        valid={!errors.email}
                    />
                    {errors.email && <div className="error-text">{errors.email}</div>}
                </div>
                <div className="form-group">
                    <label>Monetization Model</label>
                    <div className="radio-group">
                        <label
                            className={`radio-label ${formData.monetizationModel === 'SUBSCRIPTION' ? 'selected' : ''}`}>
                            <input
                                type="radio"
                                name="monetizationModel"
                                value="SUBSCRIPTION"
                                checked={formData.monetizationModel === 'SUBSCRIPTION'}
                                onChange={handleModelChange}
                            />
                            <span className="radio-label-text">Subscription</span>
                        </label>
                        <label
                            className={`radio-label ${formData.monetizationModel === 'COMMISSION' ? 'selected' : ''}`}>
                            <input
                                type="radio"
                                name="monetizationModel"
                                value="COMMISSION"
                                checked={formData.monetizationModel === 'COMMISSION'}
                                onChange={handleModelChange}
                            />
                            <span className="radio-label-text">Commission</span>
                        </label>
                    </div>
                </div>

                {/* Commission-specific fields */}
                {formData.monetizationModel === 'COMMISSION' && (
                    <div className="commission-fields">
                        <br/>
                        <div className="form-group">
                            <FormInput
                                type="number"
                                title="Commission Percentage"
                                name="commissionPercentage"
                                value={formData.commissionPercentage}
                                handleFormChange={handleFormChange}
                                placeholder="Enter percentage (0-100)"
                                valid={!errors.commissionPercentage}
                            />
                            {errors.commissionPercentage &&
                                <div className="error-text">{errors.commissionPercentage}</div>}
                        </div>
                        <br/>
                        <div className="form-group">
                            <FormInput
                                type="text"
                                title="Promo Code"
                                name="promoCode"
                                value={formData.promoCode}
                                handleFormChange={handleFormChange}
                                placeholder="e.g., promo_1R94nYHGHSjOG9hhjOl6XGg2"
                                valid={formData.promoCode === "" || formData.promoCode.startsWith("promo_")}

                            />
                            {errors.promoCode && <div className="error-text">{errors.promoCode}</div>}
                        </div>
                        <br/>
                        <div className="form-group">
                            <FormInput
                                type="number"
                                title="Trial Days"
                                name="trialDays"
                                value={formData.trialDays}
                                handleFormChange={handleFormChange}
                                placeholder="Enter number of trial days"
                                valid={!errors.trialDays}
                            />
                            <br/>
                            <div className="form-group">
                                <select
                                    name="priceId"
                                    value={formData.priceId}
                                    onChange={(e) => handleFormChange(e.target)}
                                    className={`form-select ${errors.priceId ? 'error-input' : ''}`}
                                    required
                                >
                                    <option value="">Select a price plan</option>
                                    {loadingPrices ? (
                                        <option disabled>Loading prices...</option>
                                    ) : (
                                        prices.map(price => (
                                            <option key={price.priceId} value={price.priceId}>
                                                {price.duration} - {price.priceString}
                                            </option>
                                        ))
                                    )}
                                </select>
                                {errors.priceId && <div className="error-text">{errors.priceId}</div>}
                            </div>


                            {errors.trialDays && <div className="error-text">{errors.trialDays}</div>}
                        </div>
                    </div>
                )}

                <div className="form-actions">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleCancel}
                        disabled={loading}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Teacher'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TeacherCreateComponent;