import React, {useState, useEffect} from 'react';
import {useParams, Routes, Route, useNavigate, Link} from 'react-router-dom';
import {doAuthenticatedRequest} from '../../../api/requests';
import {handleError} from '../../../api/errorHandling';
import TeacherStudentsComponent from './TeacherStudentsComponent';
import './TeacherDetail.css';

const TeacherDetailComponent = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [teacher, setTeacher] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [copyFeedback, setCopyFeedback] = useState(false);
    const [priceDetails, setPriceDetails] = useState(null);


    useEffect(() => {
        fetchTeacherDetails();
    }, []);

    useEffect(() => {
        if (teacher) {
            fetchPrices();
        }
    }, [teacher]); // This will run whenever teacher changes

    const fetchTeacherDetails = async () => {
        setLoading(true);
        try {
            const response = await doAuthenticatedRequest(
                'GET', `/teachers/${id}`
            );
            setTeacher(response.data);
            setLoading(false);
        } catch (error) {
            setError(handleError(error));
            setLoading(false);
        }
    };

    const fetchPrices = async () => {
        if (teacher.priceId) {
            try {
                const response = await doAuthenticatedRequest(
                    'GET', '/admin/prices'
                );

                if (response.data) {
                    const prices = response.data;
                    console.log(prices);
                    const matchingPrice = prices.find(price => price.priceId === teacher.priceId);
                    if (matchingPrice) {
                        setPriceDetails(matchingPrice);
                    }
                }
            } catch (error) {
                console.error('Error fetching price details:', error);
            }
        }
        ;
    }

    if (loading) {
        return <div className="loading">Loading teacher details...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (!teacher) {
        return <div className="not-found">Teacher not found</div>;
    }

    return (
        <div className="teacher-detail-container">
            <div className="detail-header">
                <div className="back-button">
                    <Link to="/account/teachers">&larr; Back to Teachers</Link>
                </div>
            </div>

            <div className="tab-content">
                <div className="teacher-info">
                    <div className="info-section">
                        <h3>Basic Information</h3>
                        <div className="info-row">
                            <div className="info-label">Name:</div>
                            <div className="info-value">{teacher.name}</div>
                        </div>
                        <div className="info-row">
                            <div className="info-label">Email:</div>
                            <div className="info-value">{teacher.email}</div>
                        </div>
                        <div className="info-row">
                            <div className="info-label">Monetization Model:</div>
                            <div className="info-value">{teacher.monetizationModel}</div>
                        </div>

                        {teacher.monetizationModel === 'SUBSCRIPTION' && teacher.subscriptionId && (
                            <div className="info-row">
                                <div className="info-label">Subscription:</div>
                                <div className="info-value">
                                    <span className="subscription-id">{teacher.subscriptionId}</span>
                                    <button
                                        onClick={() => window.open(`https://dashboard.stripe.com/subscriptions/${teacher.subscriptionId}`, '_blank', 'noopener,noreferrer')}
                                        className="btn btn-secondary stripe-btn"
                                    >
                                        Open in Stripe
                                    </button>
                                </div>
                            </div>
                        )}

                        {teacher.promoCode && (
                            <div className="info-row">
                                <div className="info-label">Promo Code:</div>
                                <div className="info-value">
                                    {teacher.promoCode ? (
                                        <a
                                            href={`https://dashboard.stripe.com/promotion_codes/${teacher.promoCode}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                color: '#3b82f6',
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {teacher.promoCode}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </div>
                        )}

                        {teacher.trialDays !== null && (
                            <div className="info-row">
                                <div className="info-label">Trial Days:</div>
                                <div className="info-value">{teacher.trialDays}</div>
                            </div>
                        )}

                        {teacher.commissionPercentage !== null && (
                            <div className="info-row">
                                <div className="info-label">Commission:</div>
                                <div className="info-value">{Math.round(teacher.commissionPercentage * 100)}%</div>
                            </div>
                        )}
                        {teacher.stripeLink !== null && (
                            <div className="info-row">
                                <div className="info-label">Stripe Link:</div>
                                <div className="info-value">
                                    <button
                                        onClick={() => {
                                            navigator.clipboard.writeText(teacher.stripeLink);
                                            setCopyFeedback(true);
                                            setTimeout(() => setCopyFeedback(false), 2000);
                                        }}
                                        className="btn btn-primary"
                                    >
                                        {copyFeedback ? 'Copied!' : 'Copy Link'}
                                    </button>

                                    <button
                                        onClick={() => window.open(teacher.stripeLink, '_blank', 'noopener,noreferrer')}
                                        className="btn btn-secondary"
                                    >
                                        Open Link
                                    </button>
                                </div>
                            </div>
                        )}
                        {priceDetails !== null && (
                            <div className="info-row">
                                <div className="info-label">Pricing Plan:</div>
                                <div className="info-value">{priceDetails.priceString}</div>
                            </div>
                        )}
                    </div>

                    <div className="info-section">
                        <h3>Students</h3>
                        {teacher.students && teacher.students.length > 0 ? (
                            <div className="student-list-preview">
                                <table className="student-table">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>License Period</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {teacher.students.slice(0, 5).map(student => (
                                        <tr key={student.id}>
                                            <td>{student.name}</td>
                                            <td>{student.email}</td>
                                            <td>
                              <span className={`status-badge ${student.status.toLowerCase()}`}>
                                {student.status}
                              </span>
                                            </td>
                                            <td>
                                                {student.licenseStartDate ? (
                                                    student.licenseEndDate ?
                                                        `${new Date(student.licenseStartDate).toLocaleDateString()} - ${new Date(student.licenseEndDate).toLocaleDateString()}` :
                                                        `${new Date(student.licenseStartDate).toLocaleDateString()} - No end date`
                                                ) : (
                                                    <span className="no-date">Not set</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="no-students">No students found for this teacher</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeacherDetailComponent;