import React, {useState} from 'react';

const SubscriptionTeacherList = ({teachers, onViewDetails, onDeleteTeacher}) => {
    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');

    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const sortedTeachers = [...teachers].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
    });

    if (teachers.length === 0) {
        return <div className="no-teachers">No subscription teachers found</div>;
    }

    return (
        <table className="teacher-table">
            <thead>
            <tr>
                <th onClick={() => handleSort('name')}>
                    Name {sortField === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('email')}>
                    Email {sortField === 'email' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th>Stripe Subscription</th>

                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {sortedTeachers.map(teacher => (
                <tr key={teacher.id}>
                    <td>{teacher.name}</td>
                    <td>{teacher.email}</td>
                    <td>
                        {teacher.subscriptionId ? (
                            <button
                                onClick={() => window.open(`https://dashboard.stripe.com/subscriptions/${teacher.subscriptionId}`, '_blank', 'noopener,noreferrer')}
                                className="btn btn-primary"
                            >
                                Stripe
                            </button>
                        ) : (
                            <span className="no-subscription">Not available</span>
                        )}
                    </td>

                    <td className="actions">
                        <button className="btn btn-secondary" onClick={() => onViewDetails(teacher.id)}>View</button>
                        {teacher.studentCount === 0 && (
                            <button
                                className="btn btn-delete"
                                onClick={() => onDeleteTeacher(teacher.id)}
                                style={{
                                    backgroundColor: '#ef4444',
                                    color: 'white',
                                    marginLeft: '8px',
                                    border: 'none',
                                    borderRadius: '4px',
                                    padding: '8px 16px',
                                    cursor: 'pointer'
                                }}
                            >
                                Delete
                            </button>
                        )}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default SubscriptionTeacherList;